<template>
  <a-spin :spinning="spinShow">
    <a-card style="margin-bottom: 30px">
      <a-steps class="steps" :current="currentTab" size="small">
        <a-step title="调度"/>
        <a-step title="处理"/>
        <a-step title="回访 "/>
        <a-step title="确认"/>
        <a-step title="完成 "/>
      </a-steps>
    </a-card>
    <a-timeline style="margin-top: 16px;margin-left: 21px">
      <a-timeline-item>
        <a-avatar slot="dot" style="backgroundColor:#0099FF" :size="53">
          登记信息
        </a-avatar>
        <a-descriptions :column="2" style="margin-top: 16px;margin-left: 21px">
          <a-descriptions-item label="人员名称">{{formItem.fromName}}</a-descriptions-item>
          <a-descriptions-item label="人员电话">{{formItem.fromPhone}}</a-descriptions-item>
          <a-descriptions-item label="人员地址">{{ formItem.fromAddress}}</a-descriptions-item>
          <a-descriptions-item label="上报时间">{{ formItem.fromTime}}</a-descriptions-item>
          <!-- <a-descriptions-item label="上报类型">{{ tempType}}</a-descriptions-item>-->
        </a-descriptions>
        <a-descriptions :column="2" style="margin-left: 21px">
          <a-descriptions-item label="上报原因">{{ formItem.reason}}</a-descriptions-item>
        </a-descriptions>
        <a-descriptions :column="2" style="margin-left: 21px">
          <a-descriptions-item label="图片信息" v-if="images.length">
            <template>
              <qiniu-image-upload ref="qiniuImageRef" type="view" :items="images" style="margin-top: -15px"/>
            </template>
          </a-descriptions-item>
        </a-descriptions>
      </a-timeline-item>
      <a-timeline-item
        v-if="formItem.handleStatus=== 'PROCESSING'||formItem.handleStatus=== 'PROCESSED'||formItem.handleStatus=== 'FEEDBACK_COMPLETE'||formItem.handleStatus=== 'CONFIRM_COMPLETE'">
        <a-avatar slot="dot" style="backgroundColor:#0099FF" :size="53">
          调度信息
        </a-avatar>
        <a-descriptions :column="2" style="margin-top: 16px;margin-left: 21px">
          <a-descriptions-item label="调度人员">{{ formItem.officer}}</a-descriptions-item>
          <a-descriptions-item label="调度时间">{{ formItem.dispatchTime}}</a-descriptions-item>
          <a-descriptions-item label="工单绑定">
            <a-button v-if="formItem.bind && tempBind!=='未绑定工单'" type="link" @click="viewDetail">{{tempBind}}</a-button>
            <span v-else>{{tempBind}}</span>
          </a-descriptions-item>
          <a-descriptions-item label="派车记录" v-if="carRecordId !== null">
            <a-button type="link" @click="viewCarDetail">详情</a-button>
          </a-descriptions-item>
        </a-descriptions>
        <a-descriptions :column="2" style="margin-left: 21px">
          <a-descriptions-item label="调度意见">{{ formItem.resolution}}</a-descriptions-item>
        </a-descriptions>
      </a-timeline-item>
      <a-timeline-item
        v-if="formItem.handleStatus=== 'PROCESSED'||formItem.handleStatus=== 'FEEDBACK_COMPLETE'||formItem.handleStatus=== 'CONFIRM_COMPLETE'">
        <a-avatar slot="dot" style="backgroundColor:#0099FF" :size="53">
          处理结果
        </a-avatar>
        <a-descriptions :column="2" style="margin-top: 16px;margin-left: 21px">
          <a-descriptions-item label="处理人">{{ formItem.handlePerson}}</a-descriptions-item>
          <a-descriptions-item label="处理时间">{{ formItem.handleCompleteTime}}</a-descriptions-item>
          <a-descriptions-item label="处理结果">{{ formItem.handleResult}}</a-descriptions-item>
        </a-descriptions>
      </a-timeline-item>
      <a-timeline-item v-if="formItem.handleStatus=== 'FEEDBACK_COMPLETE'||formItem.handleStatus=== 'CONFIRM_COMPLETE'">
        <a-avatar slot="dot" style="backgroundColor:#0099FF" :size="53">
          回访信息
        </a-avatar>
        <a-descriptions :column="2" style="margin-top: 16px;margin-left: 21px">
          <a-descriptions-item label="回访人">{{ formItem.feedbackPerson}}</a-descriptions-item>
          <a-descriptions-item label="回访时间">{{ formItem.feedbackTime}}</a-descriptions-item>
          <a-descriptions-item label="回访结果">{{ formItem.feedbackResult}}</a-descriptions-item>
        </a-descriptions>
      </a-timeline-item>
      <a-timeline-item v-if="formItem.handleStatus=== 'CONFIRM_COMPLETE'">
        <a-avatar slot="dot" style="backgroundColor:#0099FF" :size="53">
          确认信息
        </a-avatar>
        <a-descriptions :column="2" style="margin-top: 16px;margin-left: 21px">
          <a-descriptions-item label="确认人">{{ formItem.confirmPerson}}</a-descriptions-item>
          <a-descriptions-item label="确认时间">{{ formItem.confirmTime}}</a-descriptions-item>
          <a-descriptions-item label="确认结果">{{ formItem.confirmResult}}</a-descriptions-item>
        </a-descriptions>
      </a-timeline-item>
    </a-timeline>
    <a-modal
      title="工单详情"
      :visible="ticketDetailModal"
      :maskClosable="true"
      centered
      :footer="false"
      @ok="handleOk"
      @cancel="detailClose">
      <ticket-info-detail ref="viewRef"></ticket-info-detail>
    </a-modal>
    <a-modal
      title="车辆记录详情"
      :visible="carDetailModal"
      :maskClosable="true"
      centered
      :width=1500
      :footer="false"
      @ok="handleCarOk"
      @cancel="detailCarClose">
      <car-record-detail ref="carDetailRef"></car-record-detail>
    </a-modal>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { damageReportFormVO } from './common/common'
  import QiniuImageUpload from '../../../components/QiniuUpload/QiniuImageUpload'
  import TicketInfoDetail from '../info/Detail'
  import CarRecordDetail from '../../car/record/Detail'

  export default {
    name: 'InputDetails',
    data() {
      return {
        formItem: damageReportFormVO(),
        carRecordId: null, // 车俩记录的ID
        tempType: '',
        spinShow: false,
        currentTab: 1,
        images: [],
        tempBind: '未绑定工单',
        ticketInfoId: '', //ticketInfo表的主键id
        ticketDetailModal: false,
        carDetailModal: false
      }
    },
    // eslint-disable-next-line standard/object-curly-even-spacing
    components: { QiniuImageUpload, TicketInfoDetail, CarRecordDetail },
    methods: {
      loadData(id) {
        this.formItem = damageReportFormVO()
        this.carRecordId = null
        this.$http(this, {
          url: SERVICE_URLS.ticket.damageReport.view,
          params: {
            id: id
          },
          noTips: 'true',
          success: (data) => {
            Object.assign(this.formItem, data.body.damageReportRecord)
            if (data.body.carRecord && data.body.carRecord.id !== null) {
              this.carRecordId = data.body.carRecord.id
            }
            this.images = data.body.images
            this.handleDate(data.body)
          }
        })
      },
     /* loadViewData(id) {
        this.formItem = damageReportFormVO()
        this.carRecordId = null
        this.$http(this, {
          url: SERVICE_URLS.ticket.damageReport.view,
          params: {
            id: id
          },
          noTips: 'true',
          success: (data) => {
            Object.assign(this.formItem, data.body.damageReportRecord)
            if (data.body.carRecord && data.body.carRecord.id !== null) {
              this.carRecordId = data.body.carRecord.id
            }
            this.images = data.body.images
            this.handleDate(data.body)
          }
        })
      },*/
      handleDate(date) {
        if (this.formItem.handleStatus === 'UN_PROCESS') {
          this.currentTab = 0
        } else if (this.formItem.handleStatus === 'PROCESSING') {
          this.currentTab = 1
        } else if (this.formItem.handleStatus === 'PROCESSED') {
          this.currentTab = 2
        } else if (this.formItem.handleStatus === 'FEEDBACK_COMPLETE') {
          this.currentTab = 3
        } else if (this.formItem.handleStatus === 'CONFIRM_COMPLETE') {
          this.currentTab = 4
        }
        if (this.formItem.bind) {
          this.tempBind = '' + date.ticketDate[0][0]//已绑定工单名称
          this.ticketInfoId = date.ticketDate[0][1]//绑定工单的id
          console.log(this.ticketInfoId + '测试')
        } else if (!this.formItem.bind) {
          this.tempBind = '未绑定工单'
        }
        if (this.formItem.type === 'TEL') {
          this.tempType = '电话上报'
        } else if (this.formItem.type === 'WECHAT') {
          this.tempType = '微信上报'
        } else if (this.formItem.type === 'OTHER') {
          this.tempType = '其他途径'
        }
      },
      detailClose() {
        this.ticketDetailModal = false
      },
      detailCarClose() {
        this.carDetailModal = false
      },
      handleOk() {
        this.ticketDetailModal = false
      },
      handleCarOk() {
        this.carDetailModal = false
      },
      viewDetail() {
        if (this.formItem.bind) {
          this.ticketDetailModal = true
          const self = this
          self.$nextTick(() => {
            self.$refs.viewRef.loadData(self.ticketInfoId)
          })
        }
      },
      viewCarDetail() {
        this.carDetailModal = true
        // const self = this
         this.$nextTick(() => {
        this.$refs.carDetailRef.loadData(this.carRecordId)
         })
      }
    }
  }
</script>
