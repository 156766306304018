export const reportColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '人员名称',
      align: 'center',
      width: 140,
      dataIndex: 'fromName'
    },
    {
      title: '上报原因',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'reason'
    },
    {
      title: '上报地址',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromAddress'
    },
    {
      title: '来电电话',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromPhone'
    },
    {
      title: '故障类型',
      align: 'center',
      width: 120,
      dataIndex: 'damageType'
    },
    {
      title: '值班人员',
      align: 'center',
      width: 140,
      dataIndex: 'officer'
    },
    {
      title: '绑定工单',
      align: 'center',
      width: 120,
      dataIndex: 'bind',
      scopedSlots: { customRender: 'bind' }
    },
    {
      title: '处理状态',
      align: 'center',
      width: 120,
      dataIndex: 'handleStatus',
      scopedSlots: { customRender: 'handleStatus' }
    },
    {
      title: '上报类型',
      align: 'center',
      width: 140,
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '上报时间',
      align: 'center',
      width: 200,
      dataIndex: 'fromTime'
    },
    /* {
       title: '确认人',
       align: 'center'，
       width: '90px',
       dataIndex: 'confirmUserId'
     },*/
    {
      title: '操作',
      align: 'center',
      width: '100px',
      fixed: 'right',
      scopedSlots: { customRender: 'action' }
    }
  ]
}
export const damageReportColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '人员名称',
      align: 'center',
      width: 140,
      dataIndex: 'fromName'
    },
    {
      title: '上报原因',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'reason'
    },
    {
      title: '上报地址',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromAddress'
    },
    {
      title: '来电电话',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromPhone'
    },
    {
      title: '故障类型',
      align: 'center',
      width: 120,
      dataIndex: 'damageType'
    },
    {
      title: '值班人员',
      align: 'center',
      width: 120,
      dataIndex: 'officer'
    },
    {
      title: '绑定工单',
      align: 'center',
      width: 120,
      dataIndex: 'bind',
      scopedSlots: { customRender: 'bind' }
    },
    {
      title: '派车信息',
      align: 'center',
      width: 120,
      dataIndex: 'dispatchStatus ',
      scopedSlots: { customRender: 'dispatch' }
    },
    {
      title: '处理状态',
      align: 'center',
      width: 120,
      dataIndex: 'handleStatus',
      scopedSlots: { customRender: 'handleStatus' }
    },
    {
      title: '上报类型',
      align: 'center',
      width: 140,
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '上报时间',
      align: 'center',
      width: 200,
      dataIndex: 'fromTime'
    },
    /* {
       title: '确认人',
       align: 'center'，
       width: '90px',
       dataIndex: 'confirmUserId'
     },*/
    {
      title: '操作',
      align: 'center',
      width: 140,
      fixed: 'right',
      scopedSlots: { customRender: 'action' }
    }
  ]
}
export const damageReportDispatchColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '人员名称',
      align: 'center',
      width: 140,
      dataIndex: 'fromName'
    },
    {
      title: '上报原因',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'reason'
    },
    {
      title: '上报地址',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromAddress'
    },
    {
      title: '来电电话',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromPhone'
    },
    {
      title: '故障类型',
      align: 'center',
      width: 120,
      dataIndex: 'damageType'
    },
    {
      title: '值班人员',
      align: 'center',
      width: 120,
      dataIndex: 'officer'
    },
    {
      title: '绑定工单',
      align: 'center',
      width: 120,
      dataIndex: 'bind',
      scopedSlots: { customRender: 'bind' }
    },
    {
      title: '处理状态',
      align: 'center',
      width: 120,
      dataIndex: 'handleStatus',
      scopedSlots: { customRender: 'handleStatus' }
    },
    {
      title: '上报类型',
      align: 'center',
      width: 140,
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '上报时间',
      align: 'center',
      width: 200,
      dataIndex: 'fromTime'
    },
    /* {
       title: '确认人',
       align: 'center'，
       width: '90px',
       dataIndex: 'confirmUserId'
     },*/
    {
      title: '操作',
      align: 'center',
      width: 140,
      fixed: 'right',
      scopedSlots: { customRender: 'action' }
    }
  ]
}
export const damageReportFeedbackColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '人员名称',
      align: 'center',
      width: 140,
      dataIndex: 'fromName'
    },
    {
      title: '上报原因',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'reason'
    },
    {
      title: '上报地址',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromAddress'
    },
    {
      title: '来电电话',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromPhone'
    },
    {
      title: '故障类型',
      align: 'center',
      width: 120,
      dataIndex: 'damageType'
    },
    {
      title: '值班人员',
      align: 'center',
      width: 120,
      dataIndex: 'officer'
    },
    {
      title: '绑定工单',
      align: 'center',
      width: 120,
      dataIndex: 'bind',
      scopedSlots: { customRender: 'bind' }
    },
    {
      title: '派车信息',
      align: 'center',
      width: 120,
      dataIndex: 'dispatchStatus ',
      scopedSlots: { customRender: 'dispatch' }
    },
    {
      title: '处理状态',
      align: 'center',
      width: 120,
      dataIndex: 'handleStatus',
      scopedSlots: { customRender: 'handleStatus' }
    },
    {
      title: '上报类型',
      align: 'center',
      width: 140,
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '上报时间',
      align: 'center',
      width: 200,
      dataIndex: 'fromTime'
    },
    /* {
       title: '确认人',
       align: 'center'，
       width: '90px',
       dataIndex: 'confirmUserId'
     },*/
    {
      title: '操作',
      align: 'center',
      width: 140,
      fixed: 'right',
      scopedSlots: { customRender: 'action' }
    }
  ]
}
export const damageReportConfirmColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '人员名称',
      align: 'center',
      width: 140,
      dataIndex: 'fromName'
    },
    {
      title: '上报原因',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'reason'
    },
    {
      title: '上报地址',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromAddress'
    },
    {
      title: '来电电话',
      align: 'left',
      width: 150,
      ellipsis: true,
      dataIndex: 'fromPhone'
    },
    {
      title: '故障类型',
      align: 'center',
      width: 120,
      dataIndex: 'damageType'
    },
    {
      title: '值班人员',
      align: 'center',
      width: 120,
      dataIndex: 'officer'
    },
    {
      title: '绑定工单',
      align: 'center',
      width: 120,
      dataIndex: 'bind',
      scopedSlots: { customRender: 'bind' }
    },
    {
      title: '派车信息',
      align: 'center',
      width: 120,
      dataIndex: 'dispatchStatus ',
      scopedSlots: { customRender: 'dispatch' }
    },
    {
      title: '处理状态',
      align: 'center',
      width: 120,
      dataIndex: 'handleStatus',
      scopedSlots: { customRender: 'handleStatus' }
    },
    {
      title: '上报类型',
      align: 'center',
      width: 140,
      dataIndex: 'type',
      scopedSlots: { customRender: 'type' }
    },
    {
      title: '上报时间',
      align: 'center',
      width: 200,
      dataIndex: 'fromTime'
    },
    /* {
       title: '确认人',
       align: 'center'，
       width: '90px',
       dataIndex: 'confirmUserId'
     },*/
    {
      title: '操作',
      align: 'center',
      width: 160,
      fixed: 'right',
      scopedSlots: { customRender: 'action' }
    }
  ]
}
export const damageReportFormVO = () => {
  return {
    departmentId: '',
    fromName: '',
    fromPhone: '',
    fromAddress: '',
    reason: null,
    type: 'TEL',
    imageIds: [],
    images: [],
    handleStatus: 'UN_PROCESS',
    bind: false, //是否绑定工单
    resolution: '', //调度中心处理意见
    officer: '', //调度中心值班人
    officePhone: '', //值班电话
    handleResult: '', //现场处理意见
    handlePerson: '', //现场处理人
    confirmResult: '', //确认结果
    feedbackPerson: '', //回访人
    confirmPerson: '', //确认人
    damageType: '公共设施',
    dispatchStatus: 'NO_NEED',
    department: { id: null }
  }
}

export const reportTypeList = () => {
  return [
    { value: 'TEL', label: '电话上报' },
    { value: 'WECHAT', label: '微信上报' },
    { value: 'OTHER', label: '其他途径' }
  ]
}
export const processStatusList = () => {
  return [
    { value: 'UN_PROCESS', label: '待调度' },
    { value: 'PROCESSING', label: '待处理' },
    { value: 'PROCESSED', label: '待回访' },
    { value: 'FEEDBACK_COMPLETE', label: '待确认' },
    { value: 'CONFIRM_COMPLETE', label: '已完成' }
  ]
}

// 统计报表表头
export const statisticsColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '时间',
      align: 'center',
      children: [
        {
          title: '日',
          align: 'center',
          key: 'day',
          width: 100,
          customRender: (text, record) => {
            let fromTime = record.fromTime
            if (fromTime) {
              if (fromTime.slice(8, 9) === '0') {
                return fromTime.slice(9, 10)
              } else {
                return fromTime.slice(8, 10)
              }
            }
          }
        },
        {
          title: '时分',
          align: 'center',
          key: 'hour_minute',
          width: 100,
          customRender: (text, record) => {
            return `${record.fromTime.slice(11, 16)}`
          }
        }
      ]
    },
    {
      title: '来电人',
      align: 'center',
      children: [
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'fromName',
          width: 120
        },
        {
          title: '电话',
          align: 'center',
          dataIndex: 'fromPhone',
          width: 120
        }
      ]
    },
    {
      title: '值班员',
      align: 'center',
      children: [
        {
          title: '姓名',
          align: 'center',
          dataIndex: 'officer',
          width: 120
        },
        {
          title: '电话',
          align: 'center',
          dataIndex: 'officePhone',
          width: 120
        }
      ]
    },
    {
      title: '内容',
      align: 'center',
      width: 300,
      ellipsis: true,
      scopedSlots: { customRender: 'reason' }
    },
    {
      title: '办理结果',
      align: 'center',
      width: 150,
      ellipsis: true,
      dataIndex: 'confirmResult'
    },
    {
      title: '备注',
      align: 'center',
      width: 150
    }
  ]
}

// 部门值班电话
export const departmentPhoneNumbers = () => {
  return [
    { value: '30', label: '967001-1' }, // 兴庆
    { value: '29', label: '967001-2' }, // 金凤
    { value: '31', label: '967001-3' }, // 贺兰
    { value: '46', label: '967001-4' }, // 中宁
  ]
}