<template>
  <div>
    <qiniu-upload
      ref="qiniuFileUpload"
      :pickfiles="'pickfiles-files-' + uploadUUID"
      :container="'container-files-' + uploadUUID"
      :documentDirId="documentDirId"
      :autoStart="autoStart"
      :multiSelection="multiSelection"
      :filters="filters"
      :notShowDefaultList="true"
      :maxSize="maxSize"
      :updaloadTypes="updaloadTypes"
      @onFilesAdded="onFilesAdded"
      @onOneUploadComplete="onOneUploadComplete"
      @onUploadComplete="onUploadComplete"
      @onBeforeUpload="onBeforeUpload"
      @onError="onError"
    >
      <div slot="uploader">
        <div :id="'container-files-' + uploadUUID">
          <div v-if="type === 'edit'">
            <a-button
              type="dashed"
              :loading="uploadStatus"
              class="m-r-12 cursor-pointer"
              icon="cloud-upload"
              :id="'pickfiles-files-' + uploadUUID"
            >上传附件
            </a-button>
            {{ tips }}
          </div>
        </div>
      </div>
    </qiniu-upload>
    <div class="file-page">
      <ul class="upload-list-ul" v-if="files.length > 0">
        <li class="upload-list-li" v-for="file in files" style="padding: 0px; margin-bottom: 5px;">
          <a-row style="border: 1px dashed #dddee1; border-radius: 4px;">
            <a-col :span="23" style="line-height: 33px; font-size: 14px;">
              <span>
                <a-icon
                  type="paper-clip"
                  style="font-weight: normal; margin-right: 10px; color: grey; margin-left: 5px;"
                />
                <a :title="file.name" style="color: #495060;">{{ file.title | subString }}</a>
                &nbsp;<span style="float: right;">{{ file.fileSize }}</span></span
              >
            </a-col>
            <a-col :span="1">
              <a-icon
                v-if="type === 'edit'"
                type="delete"
                @click="removeFile(file)"
                style="
                  float: right;
                  line-height: 33px;
                  font-size: 14px;
                  margin-right: 8px;
                  color: red;
                  cursor: pointer;
                "
              />
              <a-icon
                v-if="download"
                type="cloud-download"
                @click="downloadFile(file)"
                style="
                  float: right;
                  line-height: 33px;
                  font-size: 14px;
                  margin-right: 8px;
                  color: green;
                  cursor: pointer;"/>
            </a-col>
          </a-row>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import QiniuUpload from './QiniuUpload'
import uploadCommon from '../../components/LocalUpload/mixins/upload-common'
import { fileIconFormat, humanReadableUnits } from '../../utils/utils'
import dragAndDrop from '../../directives/drag-and-drop'
import VueCookie from 'vue-cookie'
import '../../plugins/fileDownload/download.min'
import { DEFAULT_X_AUTH_TOKEN } from '../../store/mutation-types'
import { mapState } from 'vuex'

export default {
  name: 'QiniuFileUpload',
  props: {
    type: {
      type: String,
      default: 'edit'
    },
    download: {
      type: Boolean,
      default: false
    },
    maxSize: {
      type: String,
      default: '100mb'
    },
    documentDirId: {
      type: Number
    },
    multiSelection: {
      type: Boolean,
      default: false
    },
    instanceType: {
      type: String,
      default: ''
    },
    autoStart: {
      type: Boolean,
      default: true
    },
    filters: {
      type: Object
    },
    tips: {
      type: String,
      default: '支持文件拓展名:.doc .docx .pdf'
    },
    updaloadTypes: {
      type: Array,
      default: function () {
        return ['doc', 'docx', 'pdf'];
      },
    },
    items: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  mixins: [uploadCommon],
  data() {
    return {
      format: fileIconFormat,
      humanReadableUnits: humanReadableUnits,
      files: [],
      uploadFiles: []
    }
  },
  filters: {
    subString(value) {
      // if (!value) return ''
      // if (value.length > 20) {
      //   return value.substring(0, 20) + '...'
      // }
      return value
    }
  },
  watch: {
    items() {
      this.forEachItems()
    }
  },
  created() {
    this.forEachItems()
  },
  computed: {
    ...mapState({
      bucketUrl: (state) => {
        return state.user.bucketUrl
      }
    })
  },
  methods: {
    forEachItems() {
      this.files = []
      this.items.forEach((data) => {
        this.files.push({
          src: this.bucketUrl + data.fileUrl,
          msrc: this.bucketUrl + data.fileUrl,
          title: data.name,
          fileSize: humanReadableUnits(data.fileSize),
          id: data.id
        })
      })
    },
    // 上传增加
    onFilesAdded(files, allFiles) {
      //this.files = allFiles
    },
    removeFile(file) {
      this.$refs.qiniuFileUpload.removeFile(file)
      this.uploadFiles.splice(this.uploadFiles.indexOf(file), 1)
      let index_f = this.files.findIndex((i) => i.title === file.name)
      this.files.splice(index_f, 1)
      this.$emit('getFileData', this.files)
    },
    onOneUploadComplete(data) {
      this.files.push({
        src: this.bucketUrl + data.fileUrl,
        msrc: this.bucketUrl + data.fileUrl,
        fileSize: humanReadableUnits(data.fileSize),
        title: data.name,
        id: data.id
      })
    },
    onUploadComplete() {
      this.uploadStatus = false
      // 所有文件上传完成 多文件上传需要监听
      this.$emit('getFileData', this.files)
      this.$emit('onUploadComplete')
    },
    downloadFile(file) {
      // alert(file.title)
      let downUrl = file.src + '?attname=' + encodeURIComponent(file.title)
      /*let downUrl = SERVICE_URLS.document.download.path.replace('{id}', file.id) + '?attname=' + encodeURIComponent(file.name)*/
      const xAuthToken = VueCookie.get(DEFAULT_X_AUTH_TOKEN)
      if (!xAuthToken) {
        this.$Message.error({
          closable: true,
          content: '下载凭证丢失，请重新登录！',
          duration: 3
        })
        return
      }
      /*downUrl += '&' + DEFAULT_X_AUTH_TOKEN + '=' + xAuthToken*/
      /*eslint-disable-next-line*/
      downloadFile(downUrl)
    }
  },
  components: {
    QiniuUpload
  },
  directives: { dragAndDrop }
}
</script>
<style lang="less" type="text/less">
.file-page {
  margin-top: 10px;

  .upload-list-ul {
    padding-inline-start: 0px;

    .upload-list-li {
      list-style: none;
    }
  }
}
</style>
