<template>
  <a-spin :spinning="spinShow">
    <a-descriptions title="基础信息" :column="2">
      <a-descriptions-item label="工单名称">{{formItem.ticketInfo.title || '-'}}</a-descriptions-item>
      <a-descriptions-item label="所属部门">{{formItem.ticketInfo.department.name || '-'}}</a-descriptions-item>
      <a-descriptions-item label="工单来源">
        <span v-if="formItem.ticketInfo.ticketSource === 'AUTO'">自动生成<a-button type="link" @click="viewDetail" v-if="XJRecordNodeId.id">详情</a-button></span>
        <span v-else-if="formItem.ticketInfo.ticketSource === 'MANUAL'">人工创建</span>
        <span v-else-if="formItem.ticketInfo.ticketSource === 'REPORT'">电话调度</span>
        <span v-else>-</span>
      </a-descriptions-item>
      <a-descriptions-item label="处理状态">{{handleType|| '~'}}</a-descriptions-item>
      <a-descriptions-item label="开始时间">{{ formItem.ticketInfo.startProcessTime || '~'}}</a-descriptions-item>
      <a-descriptions-item label="完成时间">{{ formItem.ticketInfo.endProcessTime || '~' }}</a-descriptions-item>
      <a-descriptions-item label="创建人">{{ formItem.ticketInfo.creator.name || '-'}}</a-descriptions-item>
      <a-descriptions-item label="创建时间">{{ formItem.ticketInfo.dateCreated || '-'}}</a-descriptions-item>
    </a-descriptions>
    <a-descriptions>
      <a-descriptions-item label="描述信息">{{ formItem.ticketInfo.description || '-'}}</a-descriptions-item>
    </a-descriptions>
    <a-descriptions title="基础图片信息" :column="2" v-if="formItem.ticketImages.length">
      <div class="details-content-rests">
        <template>
          <qiniu-image-upload ref="qiniuImageRef" type="view" :items="formItem.ticketImages" style="margin-top: -15px"/>
        </template>
      </div>
    </a-descriptions>
    <a-descriptions title="处理记录" :column="2" v-if="formItem.ticketRecords.length">
    </a-descriptions>
    <a-timeline style="margin-left: 16px">
      <a-timeline-item v-for="(item, index) in formItem.ticketRecords" :key="item.id">
        <a-avatar slot="dot" v-if="index===0" style="backgroundColor:#FF6699" size="large">
          {{item.processUser.name.substring(item.processUser.name.length-3,item.processUser.name.length)}}
        </a-avatar>
        <a-avatar slot="dot" v-else style="backgroundColor:#0099FF" size="large">
          {{item.processUser.name.substring(item.processUser.name.length-3,item.processUser.name.length)}}
        </a-avatar>
        <!--<p>处理人：{{item.processUser.name}}</p>-->
        <p style="margin:14px">处理时间：{{item.processTime | formatData }}</p>
        <p style="margin:14px">处理结果：{{item.result }}</p>
        <template v-if="item.recordImages">
          <qiniu-image-upload ref="qiniuImageRef" type="view" :items="item.recordImages"/>
        </template>
        <template v-if="item.recordFiles">
          <qiniu-file-upload ref="qiniuFileRef" type="view" :items="item.recordFiles"></qiniu-file-upload>
        </template>
      </a-timeline-item>
    </a-timeline>
    <a-modal
      title="巡检详情"
      :visible="xjDetailModal"
      centered
      :maskClosable="true"
      @ok="viewOk"
      @cancel="closeModal">
      <XunjianRecordNodeDetail ref="recordNodeDetailRef"></XunjianRecordNodeDetail>
    </a-modal>
  </a-spin>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import { ticketInfoFormVO } from './common/ticketInfoFormVO'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'
  import QiniuFileUpload from '@/components/QiniuUpload/QiniuFileUpload'
  import moment from 'moment'
  import XunjianRecordNodeDetail from '../../map/composite/XunjianRecordNodeDetail'

  export default {
    name: 'DepotDetail',
    components: { XunjianRecordNodeDetail, QiniuImageUpload, QiniuFileUpload },
    data () {
      return {
        spinShow: false,
        formItem: ticketInfoFormVO(),
        labelCol: { span: 4 },
        wrapperCol: { span: 19 },
        tableData: [],
        xjDetailModal: false,
        handleType: '',
        XJRecordNodeId: {
          id: ''
        }
      }
    },
    filters: {
      formatData (data) {
        return moment(data).format('YYYY-MM-DD HH:mm:ss')
      }
    },
    methods: {
      loadData (id) {
        this.spinShow = true
        this.$http(this, {
          url: SERVICE_URLS.ticket.info.view,
          params: { id },
          noTips: true,
          success: (data) => {
            this.formItem = ticketInfoFormVO()
            Object.assign(this.formItem, data.body)
            this.XJRecordNodeId.id = data.body.XJRecordNodeId
            console.log(this.XJRecordNodeId.id, '测试id')
            if (this.formItem.ticketInfo.ticketProcessStatus === 'UN_PROCESS') {
              this.handleType = '未处理'
            } else if (this.formItem.ticketInfo.ticketProcessStatus === 'PROCESSING') {
              this.handleType = '处理中'
            } else if (this.formItem.ticketInfo.ticketProcessStatus === 'PROCESSED') {
              this.handleType = '已处理'
            }
            this.spinShow = false
          }
        })
      },
      viewDetail () {
        this.xjDetailModal = true
        this.$nextTick(() => {
          this.$refs.recordNodeDetailRef.loadData(this.XJRecordNodeId)
        })
      },
      viewOk () {
        this.xjDetailModal = false
      },
      closeModal () {
        this.xjDetailModal = false
      }
    }
  }
</script>

<style scoped>
  .text-title {
    font-size: 17px;
    font-weight: 700;
    font-family: 'Lantinghei SC', 'Open Sans', Arial, 'Hiragino Sans GB', 'Microsoft YaHei', '微软雅黑', 'STHeiti',
    'WenQuanYi Micro Hei', SimSun, sans-serif;
  }
</style>
