export const ticketInfoFormVO = function () {
  return {
    ticketInfo: {
      title: '',
      department: {
        id: null,
        name: ''
      },
      ticketType: '',
      creator: '',
      ticketProcessStatus: '',
      startProcessTime: '',
      endProcessTime: '',
      description: ''
    },
    ticketImageIds: [],
    ticketImages: [],
    handleImageIds: [],
    handleImages: [],
    fileIds: [],
    files: [],
    ticketRecords: []
  }
}