<template>
  <a-spin :spinning="spinShow">
    <template v-if="recordNode.user">
      <a-descriptions title="基础信息" :column="2">
        <a-descriptions-item label="巡检人员">{{recordNode.user.name}}</a-descriptions-item>
        <a-descriptions-item label="巡检时间">{{recordNode.dateCreated}}</a-descriptions-item>
        <a-descriptions-item label="所在位置">{{recordNode.node.area.name}}</a-descriptions-item>
        <a-descriptions-item label="巡检结果">
          <a-tag color="blue" v-if="recordNode.result === 'NORMAL'">正常</a-tag>
          <a-tag color="red" v-if="recordNode.result === 'ABNORMAL'">异常</a-tag>
        </a-descriptions-item>
        <a-descriptions-item label="备注">{{recordNode.memo}}</a-descriptions-item>
      </a-descriptions>
      <a-tabs defaultActiveKey="1" size="large" :tabBarGutter="20">
        <a-tab-pane key="1">
          <div slot="tab">
            <a-icon type="ordered-list"/>
            巡检项目
          </div>
          <div style="line-height: 30px;">
            <template v-for="(item,index) in recordItemResults">
              <div v-if="item.item.type === 'STATE'">
                <div>{{index+1}}. {{item.item.name}}:</div>
                <a-radio-group name="radioGroup" :value="item.answer" class="m-l-16 radio-not-choose">
                  <a-radio :style="radioStyle"
                           :disabled="item.answer !== option"
                           v-for="(option,index) in JSON.parse(item.item.probablyOption)"
                           :key="index" :value="option">{{String.fromCharCode(64 + index+1)}}:{{option}}
                  </a-radio>
                </a-radio-group>
              </div>
              <div v-if="item.item.type === 'NUMBER'">
                <div>{{index+1}}. {{item.item.name}}：{{item.answer}}(范围：{{item.item.minValue}}~{{item.item.maxValue}})</div>
              </div>
              <div v-if="item.item.type === 'TEXT'">
                <div>{{index+1}}. {{item.item.name}}：{{item.answer}}
                </div>
              </div>
            </template>
            <a-empty description="暂无检查项目信息" v-if="!recordItemResults.length"/>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2">
          <div slot="tab">
            <a-icon type="picture"/>
            巡检图片
          </div>
          <template v-if="images.length">
            <qiniu-image-upload ref="qiniuImageRef"
                                type="view"
                                :items="images"/>
          </template>
          <template v-else>
            <a-empty description="暂无图片信息"/>
          </template>
        </a-tab-pane>
      </a-tabs>
    </template>
  </a-spin>
</template>

<script>

  import SERVICE_URLS from '@/api/service.url'

  import QiniuImageUpload from '../../../components/QiniuUpload/QiniuImageUpload'

  export default {
    name: 'XunjianRecordNodeDetail',
    components: { QiniuImageUpload },
    data () {
      return {
        spinShow: false,
        images: [],
        recordItemResults: [],
        recordNode: {},
        radioStyle: {
          display: 'block',
          height: '30px',
          lineHeight: '30px'
        }
      }
    },
    methods: {
      /**
       * 加载巡检点描述信息
       */
      loadData (item) {
        this.$http(this, {
          url: SERVICE_URLS.xj.xjRecordNode.view,
          params: {
            id: item.id
          },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.images = data.body.images
            this.recordItemResults = data.body.recordItemResults
            this.recordNode = data.body.recordNode
          }
        })
      }
    }
  }
</script>